import { useState } from "react";
import { ConfigProvider } from "antd";
import AppRouter from "./config/router/";
import Montserrat from "./assets/fonts/Montserrat.ttf";
import AppContext from "./context/appContext";
import { getUserInfo } from "./helpers/helpers";

function App() {
  const [appParams, setAppParams] = useState({
    pin_cmp: 20,
    pin_kp: "A",
    pin_keyword1: 6731,
    pin_keyword2: "U09Z",
    pin_userid: getUserInfo()?.USERID,
    pin_password: getUserInfo()?.PASS,
  });
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#fc8019",
          fontFamily: Montserrat,
        },
      }}
    >
      <AppContext.Provider
        value={{
          appParams,
          setAppParams,
        }}
      >
        <AppRouter />
      </AppContext.Provider>
    </ConfigProvider>
  );
}

export default App;
