import React from "react";
import { HomeFilled } from "@ant-design/icons";

const MainCategories = ({
  setSelectedMainType,
  setSelectedSubType,
  mainItemTypes,
  selectedMainType,
}) => {
  return (
    <div className="bg-themeGray-medium flex items-center justify-between px-2">
      <div className="flex items-center  w-full">
        <span
          onClick={() => {
            setSelectedMainType();
            setSelectedSubType();
          }}
        >
          <HomeFilled className="text-xl text-[#808080] cursor-pointer hover:text-primary" />
        </span>
        <span
          className={`p-4 text-sm font-semibold border-r border-[#b1b1b1] whitespace-nowrap cursor-pointer ${
            selectedMainType ? "inline-block" : "hidden"
          }`}
          onClick={() => setSelectedSubType()}
        >
          {selectedMainType?.name}
        </span>
        <ul
          className={`${
            selectedMainType ? "hidden" : "flex"
          }  items-center overflow-auto thin-scroll`}
        >
          {mainItemTypes?.map((mainType, index) => {
            return (
              <li
                key={index}
                className="p-4 text-sm font-semibold border-r border-[#b1b1b1] whitespace-nowrap cursor-pointer"
                onClick={() => setSelectedMainType(mainType)}
              >
                {mainType?.name}
              </li>
            );
          })}
        </ul>
      </div>
      {/* <div className="w-3/12 hidden">
        <ThemeInput
          value={filterInput}
          onChange={(e) => setFilterInput(e.target.value)}
          placeholder={"search products..."}
          className={"mx-3 -mt-1"}
          inputClassName={"!h-10 rounded-full"}
          prefix={<SearchOutlined />}
        />
      </div> */}
    </div>
  );
};

export default MainCategories;
