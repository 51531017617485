const addKeyToStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const deleteKeyFromStorage = (key) => {
  localStorage.removeItem(key);
};

const updateKey = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const getValueFromStorage = (key) => {
  const value = localStorage.getItem(key);
  return value;
};

const getUserInfo = () => {
  try {
    let user = JSON.parse(getValueFromStorage("userInfo"));
    return user;
  } catch (err) {
    return err;
  }
};

export {
  addKeyToStorage,
  deleteKeyFromStorage,
  updateKey,
  getValueFromStorage,
  getUserInfo,
};
