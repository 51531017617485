import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";

export const itemTypesApi = createApi({
  reducerPath: "itemTypesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async (headers) => {
      headers.set("Content-type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMinItemTypes: builder.query({
      query: ({
        pin_cmp,
        pin_kp,
        pin_keyword1,
        pin_keyword2,
        pin_userid,
        pin_password,
      }) => {
        return {
          url: `getmainitemtypes`,
          method: "GET",
          params: {
            pin_cmp,
            pin_kp,
            pin_keyword1,
            pin_keyword2,
            pin_userid,
            pin_password,
          },
        };
      },
    }),
  }),
});

export const { useGetMinItemTypesQuery, useLazyGetMinItemTypesQuery } =
  itemTypesApi;
