import React from "react";
import { CloseCircleFilled } from "@ant-design/icons";

const CartRow = ({ index, product, activeRow, setActiveRow, removeItem }) => {
  let nf = new Intl.NumberFormat();
  return (
    <div
      className={`flex items-center justify-between px-4 py-1  border-b border-themeGray-medium hover:bg-[#fff2e8] cursor-pointer ${
        product?.PROD_CODE === activeRow ? "bg-[#fff2e8]" : "bg-white"
      }`}
      onClick={() => setActiveRow(product?.PROD_CODE)}
    >
      <span className="flex flex-col">
        <p className="font-semibold">{product?.PRODUCT}</p>
        <p className="ml-2">
          <span className="font-semibold">{`${nf.format(product?.qty)} `}</span>
          Unit(s) at {nf.format(product?.PRICE)} PKR / Unit(s)
        </p>
      </span>
      <div>
        <span className="font-semibold text-right flex justify-end">
          {`${nf.format(product?.lineTotal)} `}
          PKR
        </span>
        <span
          className="ml-3 flex justify-end"
          onClick={() => removeItem(index)}
        >
          <CloseCircleFilled className="text-lg hover:text-themeRed" />
        </span>
      </div>
    </div>
  );
};

export default CartRow;
