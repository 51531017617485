import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../context/appContext";
import moment from "moment";
import { SecondContainer, ThemeTable } from "../../Components";
import { EyeOutlined } from "@ant-design/icons";
import { useLazyGetSaleInvoicesQuery } from "../../services/saleInvoice";
import OrderDetailModal from "../../Components/ordersDetailModal/orderDetailModal";
const Orders = () => {
  const { appParams } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderDetail, setOrderDetail] = useState({});
  const [getSaleInvoices, { isLoading }] = useLazyGetSaleInvoicesQuery();

  useEffect(() => {
    getOrders();
  }, []);

  const onClose = () => {
    setIsModalOpen(false);
  };

  const openModal = (data) => {
    setOrderDetail(data);
    setIsModalOpen(true);
  };

  const getOrders = async () => {
    const response = await getSaleInvoices({
      ...appParams,
      pin_datatype: "INVITEMS",
      pin_fromdate: moment("2023-04-01").format("YYYY-MM-DD"),
      pin_todate: moment().format("YYYY-MM-DD"),
    });
    console.log("res", response);
    setOrders(response?.data?.results || []);
    setTotal(response?.data?.results?.length || 0);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "6%",
      render: (item, record, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "Order ID",
      dataIndex: "INVNO",
      key: "1",
      render: (text, data, id) => (
        <p className="text-center font-medium">{text}</p>
      ),
    },
    {
      title: "Customer",
      dataIndex: "CUSTOMER",
      key: "CUSTOMER",
      render: (text) => <p className="text-center font-medium">{text}</p>,
    },
    {
      title: "Order Time",
      dataIndex: "ENTRY_DATETIME",
      key: "ENTRY_DATETIME",
      render: (text) => (
        <p className="text-center font-medium">
          {moment(text).format("YYYY MM DD HH:MM:SS")}
        </p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "SUB_TOTAL",
      key: "SUB_TOTAL",
      render: (text) => <p className="text-right font-medium">{text}</p>,
    },
    {
      title: "Paid",
      dataIndex: "PAID",
      key: "PAID",
      render: (text) => <p className="text-center font-medium">{text}</p>,
    },
    {
      title: "",
      dataIndex: "",
      key: "action",
      width: "6%",
      render: (data) => (
        <span className="cursor-pointer" onClick={() => openModal(data)}>
          <EyeOutlined />
        </span>
      ),
    },
  ];
  return (
    <SecondContainer>
      <OrderDetailModal
        isOpen={isModalOpen}
        onClose={onClose}
        data={orderDetail}
      />
      <div className="p-8">
        <h1 className="text-3xl font-bold text-black">Orders</h1>
        <div className="mt-6">
          <ThemeTable
            columns={columns}
            data={orders}
            loading={isLoading}
            pagination={{ total, setCurrentPage, setResultPerPage }}
          />
        </div>
      </div>
    </SecondContainer>
  );
};

export default Orders;
