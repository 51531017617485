import { useLayoutEffect, useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { Result } from "antd";
import {
  HomeScreen,
  InvoicePrint,
  LoginScreen,
  Orders,
  POSScreen,
  PaymentScreen,
} from "../../screens";
import { getUserInfo } from "../../helpers/helpers";

function ScrollToTop() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function AppRouter() {
  const [user, setUser] = useState(getUserInfo()?.USERID);

  useEffect(() => {
    setAuthUser();
  }, []);

  const setAuthUser = (auth) => {
    const userName = getUserInfo()?.USERID;
    setUser(auth || userName);
  };

  const ProtectedRoute = ({ user, children }) => {
    if (!user) {
      return <Navigate to="/" />;
    }
    return children;
  };
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route
          exact
          path="/"
          element={<LoginScreen setAuthUser={setAuthUser} />}
        />
        <Route
          exact
          path="/home"
          element={
            <ProtectedRoute user={user}>
              <HomeScreen />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/pos"
          element={
            <ProtectedRoute user={user}>
              <POSScreen />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/orders"
          element={
            <ProtectedRoute user={user}>
              <Orders />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/payment"
          element={
            <ProtectedRoute user={user}>
              <PaymentScreen />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/invoice-print/:id"
          element={
            <ProtectedRoute user={user}>
              <InvoicePrint />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="*"
          element={
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
