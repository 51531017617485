import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";

export const counterApi = createApi({
  reducerPath: "counterApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async (headers) => {
      headers.set("Content-type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPosCounters: builder.query({
      query: ({ pin_cmp, pin_kp, pin_keyword1, pin_keyword2 }) => {
        return {
          url: `getposcounters`,
          method: "GET",
          params: {
            pin_cmp,
            pin_kp,
            pin_keyword1,
            pin_keyword2,
          },
        };
      },
    }),
    openCounter: builder.mutation({
      query: ({
        pin_cmp,
        pin_kp,
        pin_keyword1,
        pin_keyword2,
        pin_userid,
        pin_password,
        pin_posc_cd,
        pin_dt,
        pin_balance,
        body
      }) => {
        return {
          url: `/postopencounter`,
          method: "POST",
          body: body,
          params: {
            pin_cmp,
            pin_kp,
            pin_keyword1,
            pin_keyword2,
            pin_userid,
            pin_password,
            pin_posc_cd,
            pin_dt,
            pin_balance: pin_balance || 0,
          },
        };
      },
    }),
    closeCounter: builder.mutation({
      query: ({
        pin_cmp,
        pin_kp,
        pin_keyword1,
        pin_keyword2,
        pin_userid,
        pin_password,
        pin_posc_cd,
        pin_dt,
        pin_balance,
        body
      }) => {
        return {
          url: `/postclosecounter`,
          method: "POST",
          body: body,
          params: {
            pin_cmp,
            pin_kp,
            pin_keyword1,
            pin_keyword2,
            pin_userid,
            pin_password,
            pin_posc_cd,
            pin_dt,
            pin_balance: pin_balance || 0,
          },
        };
      },
    }),
  }),
});

export const {
  useGetPosCountersQuery,
  useLazyGetPosCountersQuery,
  useOpenCounterMutation,
  useCloseCounterMutation
} = counterApi;
