import { useEffect, useState, forwardRef } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { logo, user } from "../../assets/images/images";
import { Dropdown, Badge } from "antd";
import { deleteKeyFromStorage, getUserInfo } from "../../helpers/helpers";
const items = [
  {
    label: "Log Out",
    key: "1",
  },
];

const NavBar = () => {
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  const handleMenuClick = (e) => {
    if (e.key === "1") {
      deleteKeyFromStorage("userInfo");
      deleteKeyFromStorage("cartInfo");
      navigate("/");
    }
  };
  useEffect(() => {
    setUserData(getUserInfo());
  }, []);

  const menuProps = {
    items: [
      {
        label: (
          <div>
            <span className="text-base">{userData?.USERID}</span>
          </div>
        ),
      },
      ...items,
    ],
    onClick: handleMenuClick,
  };

  return (
    <div className="bg-white sticky top-0 z-10 flex items-center justify-between px-3 lg:px-16 md:px-12 sm:px-7 py-3">
      <div className="flex items-center">
        <img src={logo} className="sm:w-44 w-36 h-16" alt="" />
      </div>
      <Dropdown menu={menuProps} placement="bottomRight">
        <img
          src={userData?.picture || user}
          alt=""
          style={{ width: 50, height: 50, objectFit: "cover" }}
          className="sm:ml-14 cursor-pointer rounded-full object-cover"
        />
      </Dropdown>
    </div>
  );
};

export default NavBar;
