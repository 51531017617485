import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (builder) => ({
    getLogin: builder.query({
      query: ({
        pin_cmp,
        pin_kp,
        pin_keyword1,
        pin_keyword2,
        userName,
        password,
      }) => ({
        headers: {
          "Content-type": "application/json",
        },
        url: "getlogin",
        method: "GET",
        params: {
          pin_cmp,
          pin_kp,
          pin_keyword1,
          pin_keyword2,
          pin_userid: userName,
          pin_password: password,
        },
      }),
    }),
  }),
});

export const { useGetLoginQuery, useLazyGetLoginQuery } = authApi;
