import React from "react";
import { no_image } from "../../assets/images/images";

const ProductCard = ({ product, addProduct }) => {
  let nf = new Intl.NumberFormat();
  return (
    <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
      <div
        className="bg-white rounded-md flex flex-col items-center px-2 py-3 cursor-pointer hover:bg-[#fff2e8] border border-white hover:border-primary"
        onClick={() => addProduct(product)}
      >
        <img
          src={product?.IMAGE_URL || no_image}
          alt="item"
          className="w-10/12 h-36"
        />
        <div className="flex flex-col items-center my-4">
          <p className="text-base font-semibold text-center">
            {product?.PRODUCT}
          </p>
          <p className="text-sm font-bold mt-1">
            PKR {` ${nf.format(product?.PRICE)}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
