import react, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/appContext";
import moment from "moment";
import { SecondContainer, ThemeButton, CashModel } from "../../Components";
import {
  deleteKeyFromStorage,
  getUserInfo,
  getValueFromStorage,
  updateKey,
} from "../../helpers/helpers";
import { Col, Input, Row, message } from "antd";
import { useGetPayTermsQuery } from "../../services/payTerms";
import { useCloseCounterMutation } from "../../services/counter";
import { useCreateSaleInvoiceMutation } from "../../services/saleInvoice";

const PaymentScreen = () => {
  let nf = new Intl.NumberFormat();
  const navigate = useNavigate();
  const userInfo = getUserInfo();
  const { appParams } = useContext(AppContext);
  const { data: payTerms, isLoading } = useGetPayTermsQuery(appParams);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [cartInfo, setCartInfo] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState(
    payTerms?.results[0]?.TERM_CODE
  );
  const [recivedAmount, setRecivedAmount] = useState(0);
  const [change, setChange] = useState(0);
  const [cardNumber, setCardNumber] = useState("");
  const [closeCounter, { isLoading: closingCounter }] =
    useCloseCounterMutation();
  const [createSaleInvoice, { isLoading: proceeding }] =
    useCreateSaleInvoiceMutation();

  useEffect(() => {
    let data = JSON.parse(getValueFromStorage("cartInfo"));
    setCartInfo(data);
  }, [payTerms]);

  const onClose = () => {
    setIsModalOpen(false);
  };

  const onCloseCounter = async () => {
    const response = await closeCounter({
      ...appParams,
      pin_posc_cd: userInfo?.POSC_CD,
      pin_dt: moment().format("YYYY-MM-DD"),
      pin_balance: formData?.cash,
      body: {},
    });
    if (!response?.error) {
      setIsModalOpen(false);
      updateKey("userInfo", { ...userInfo, isCounterOpen: false });
      deleteKeyFromStorage("cartInfo");
      message.success("Session close successfully");
      navigate("/home");
    } else {
      message.error("Something went wrong!");
    }
    console.log("resp", response);
  };

  const validatePayment = async () => {
    if (paymentMethod) {
      let data = { Orderitems: [] };
      cartInfo?.items?.map((item) => {
        data.Orderitems.push({
          Prod_code: item?.PROD_CODE,
          Qty: item?.qty,
          Rate: item?.PRICE,
          Discount: 0,
          Stax: 0,
          SRB: 0,
          Amount: item?.lineTotal,
        });
      });
      const response = await createSaleInvoice({
        ...appParams,
        pin_cust_code: "00001",
        pin_term_cd: paymentMethod,
        pin_pfx: cartInfo?.platform?.value,
        pin_posc_cd: userInfo?.POSC_CD,
        pin_pos_bank_receipt: cardNumber,
        pin_rec_notes: cartInfo?.cartTotal,
        pin_refund_notes: change,
        pin_net_received: recivedAmount,
        body: data,
      });
      if (!response?.data?.error) {
        message.success("Transaction Completed");
        deleteKeyFromStorage("cartInfo");
        setFormData({});
        setCartInfo([]);
        setChange(0);
        setRecivedAmount(0);
        setPaymentMethod(payTerms?.results[0]?.TERM_CODE);
        navigate(`/invoice-print/${response?.data?.results[0]?.INV_NO}`);
      }
      console.log("res", response);
    } else {
      message.error("please select payment method!");
    }
  };
  return (
    <SecondContainer onClose={() => setIsModalOpen(true)}>
      <CashModel
        open={isModalOpen}
        onClose={onClose}
        modalType={"close"}
        formData={formData}
        setFormData={(e) => setFormData(e)}
        onSubmit={onCloseCounter}
        isLoading={closingCounter}
      />
      <div className="flex">
        <div className="w-4/12 md:block hidden">
          <div className="p-4 h-[92vh] flex flex-col justify-between">
            <div>
              <div className="flex items-center justify-between">
                <p className="text-xl font-semibold">Summary</p>
                <p className="bg-white p-2 px-4 rounded-md font-semibold text-lg ">
                  {cartInfo?.platform?.label}
                </p>
              </div>
              <ul className="my-2">
                {cartInfo?.items?.map((item) => {
                  return (
                    <li className="p-3 mb-1 bg-white">
                      <span className="flex items-center justify-between text-sm">
                        <span className="flex items-center  ">
                          <p className="font-semibold">{item?.qty}</p>{" "}
                          <p className="mx-2">x</p>
                          {item?.PRODUCT}
                        </span>
                        <p className="font-semibold ml-1">
                          {nf.format(item?.lineTotal)}
                        </p>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div>
              <div className="flex justify-between item-center py-2 border-b-2 border-themeGray-medium">
                <p className="text-lg font-semibold">Total</p>
                <p className="font-semibold text-base">
                  {nf.format(cartInfo?.cartTotal)}
                </p>
              </div>
              <p className="w-full py-2 px-4 font-semibold text-base text-primary bg-white border border-themeGray-medium rounded-md text-center cursor-pointer hover:bg-primary hover:text-white mt-4">
                Cancel Transaction
              </p>
            </div>
          </div>
        </div>
        <div className="md:w-8/12 w-full h-[92vh]">
          <div className="bg-white border-l-2 border-themeGray-medium p-8 h-full">
            <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
              {payTerms?.results?.length &&
                payTerms?.results?.map((payTerm, index) => {
                  return (
                    <Col
                      lg={8}
                      md={12}
                      sm={12}
                      xs={12}
                      className="h-20 mb-4"
                      key={index}
                    >
                      <div
                        className={`flex justify-center items-center cursor-pointer shadow-[-10px_5px_20px_-10px_rgba(0,0,0,.2)] hover:text-primary hover:bg-[#fff2e8] border border-themeGray-black w-full h-full md:p-2 p-1 ${
                          paymentMethod === payTerm?.TERM_CODE
                            ? "text-primary bg-[#fff2e8]"
                            : "text-black bg-white"
                        }`}
                        onClick={() => {
                          setPaymentMethod(payTerm?.TERM_CODE);
                          setRecivedAmount(0);
                          setChange(0);
                        }}
                      >
                        <p className="font-semibold md:text-base text-sm text-center">
                          {payTerm?.PAYMENT_TERM}
                        </p>
                      </div>
                    </Col>
                  );
                })}
            </Row>

            <div className="md:my-6 mb-6 flex md:flex-row flex-col items-center justify-between">
              <span className="bg-black text-white flex items-center py-2 px-4 h-16 md:w-5/12 w-full justify-between md:my-0 my-4">
                <p className="text-lg font-semibold">Cash Due</p>
                <span className="text-xl font-semibold">
                  {nf.format(cartInfo?.cartTotal)}
                </span>
              </span>
              {paymentMethod === "001" ? (
                <span className="flex items-center py-2 px-4 h-16 md:w-5/12 w-full justify-between bg-white border-2 border-primary text-lg md:my-0 my-4">
                  <input
                    type="number"
                    className="!border-none !outline-none h-full w-6/12"
                    placeholder="0.00"
                    min={0}
                    value={recivedAmount}
                    onChange={(e) => {
                      setRecivedAmount(e.target.value);
                      setChange(
                        e.target.value
                          ? e.target.value - cartInfo?.cartTotal
                          : 0
                      );
                    }}
                  />
                  <span>{nf.format(change.toFixed(2))}</span>
                </span>
              ) : (
                <Input
                  type="text"
                  placeholder="enter here card/voucher number...."
                  className="h-16 md:w-5/12 w-full !border-2 !border-primary !rounded-none text-base"
                  value={cardNumber}
                  onChange={(e) => setCardNumber(e.target.value)}
                />
              )}
            </div>
            <div className="w-full flex justify-end">
              <ThemeButton
                content={<p>Complete Transaction</p>}
                disabled={
                  (paymentMethod === "001" &&
                    cartInfo?.cartTotal > recivedAmount) ||
                  proceeding
                }
                onClick={validatePayment}
                className={"!h-14"}
              />
            </div>
          </div>
        </div>
      </div>
    </SecondContainer>
  );
};

export default PaymentScreen;
