import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Input, Spin } from "antd";
import { ThemeInput, ThemeButton } from "..";

const CashModel = ({
  open,
  onClose,
  modalType,
  formData,
  setFormData,
  onSubmit,
  isLoading,
}) => {
  const navigate = useNavigate()
  return (
    <Modal
      footer={false}
      open={open}
      onCancel={onClose}
      closable={false}
      maskClosable={false}
      width={600}
      className="pd-zero"
    >
      <div className="p-4 border-b border-bg-themeGray-light">
        <p className="text-center text-2xl font-bold">
          {modalType === "open" ? "Opening Cash" : "Closing Cash"}
        </p>
      </div>
      <div className="p-10">
        <div className="mb-8 flex justify-between items-center">
          <span className="font-semibold text-lg">
            {modalType === "open" ? "Opening cash" : "Closing cash"}
          </span>
          <ThemeInput
            type={"number"}
            placeholder={"0"}
            value={formData?.cash}
            name={"cash"}
            onChange={(e) =>
              setFormData({ ...formData, cash: e.target.value })
            }
            className={"w-40"}
            inputClassName={"!text-right"}
          />
        </div>
        <div>
          <Input.TextArea
            placeholder="Enter opening note..."
            name={"note"}
            value={formData?.note}
            onChange={(e) =>
              setFormData({ ...formData, note: e.target.value })
            }
            className="font-medium pt-2"
            autoSize={{
              minRows: 6,
              maxRows: 6,
            }}
          />
        </div>
      </div>

      <div className="flex justify-end p-4 border-t border-bg-themeGray-light">
        {modalType === "close" &&  <ThemeButton className={"w-40 mr-4"} content={<p>Run in Background</p>} onClick={() => navigate('/home')} />}
        <ThemeButton
        className={"w-32"}
          onClick={onSubmit}
          content={
            <>
              {" "}
              {isLoading ? (
                <Spin className="mt-1" />
              ) : (
                <p>{modalType === "open" ? "Open Session" : "Close Session"}</p>
              )}
            </>
          }
        />
      </div>
    </Modal>
  );
};

export default CashModel;
