import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";

export const payTermApi = createApi({
  reducerPath: "payTermApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async (headers) => {
      headers.set("Content-type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPayTerms: builder.query({
      query: ({
        pin_cmp,
        pin_kp,
        pin_keyword1,
        pin_keyword2,
        pin_userid,
        pin_password,
        pin_pfx_type,
      }) => {
        return {
          url: `getpayterms`,
          method: "GET",
          params: {
            pin_cmp,
            pin_kp,
            pin_keyword1,
            pin_keyword2,
            pin_userid,
            pin_password,
            pin_pfx_type,
          },
        };
      },
    }),
  }),
});

export const { useGetPayTermsQuery, useLazyGetPayTermsQuery } = payTermApi;
