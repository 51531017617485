import React, { useState, useContext, useEffect } from "react";
import AppContext from "../../context/appContext";
import { useNavigate } from "react-router-dom";
import { Form, Spin, message } from "antd";
import { ThemeButton, ThemeInput, ThemeSelect } from "../../Components";
import { login_icon } from "../../assets/icons/icons";
import { logo } from "../../assets/images/images";
import { addKeyToStorage } from "../../helpers/helpers";
import { useLazyGetLoginQuery } from "../../services/auth";
import { useGetPosCountersQuery } from "../../services/counter";
import "./index.css";

const LoginScreen = ({ setAuthUser }) => {
  const { appParams, setAppParams } = useContext(AppContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState();
  const [counteList, setCounterList] = useState([]);
  const { data: posCounters, isLoading: fetchingPosCounters } =
    useGetPosCountersQuery({
      pin_cmp: appParams?.pin_cmp,
      pin_kp: appParams?.pin_kp,
      pin_keyword1: appParams?.pin_keyword1,
      pin_keyword2: appParams?.pin_keyword2,
    });
  const [getLogin, { isLoading, isError, isSuccess }] = useLazyGetLoginQuery();

  useEffect(() => {
    let list = [];
    posCounters?.results?.length &&
      posCounters?.results?.map((counter) => {
        list.push({
          value: counter?.POSC_CD,
          label: `Counter ${counter?.COUNTER_NO}`,
        });
      });
    setFormData({ ...formData, pos_counter: list[0]?.value });
    setCounterList(list);
  }, [posCounters, appParams]);

  const onLogin = async () => {
    if (formData?.userName && formData?.password && formData?.pos_counter) {
      const response = await getLogin({
        pin_cmp: appParams?.pin_cmp,
        pin_kp: appParams?.pin_kp,
        pin_keyword1: appParams?.pin_keyword1,
        pin_keyword2: appParams?.pin_keyword2,
        userName: formData?.userName,
        password: formData?.password,
      });

      if (!response?.error) {
        if (
          !response?.data?.results[0]?.POSC_CD ||
          response?.data?.results[0]?.POSC_CD == formData?.pos_counter
        ) {
          setAuthUser(response?.data?.results[0]?.USERID);
          setAppParams({
            ...appParams,
            pin_userid: formData?.userName,
            pin_password: formData?.password,
          });
          let counter = counteList.find(
            (e) => e?.value === formData?.pos_counter
          );
          if (!response?.data?.results[0]?.COUNTER) {
            addKeyToStorage("userInfo", {
              ...response?.data?.results[0],
              PASS: formData?.password,
              POSC_CD: formData?.pos_counter,
              COUNTER: counter?.label,
              isCounterOpen: false,
            });
            navigate("/home");
          } else {
            addKeyToStorage("userInfo", {
              ...response?.data?.results[0],
              PASS: formData?.password,
              COUNTER: counter?.label,
              isCounterOpen: true,
            });
            navigate("/POS");
          }

          message.success("user login successfully");
        } else {
          message.error(
            `First close your previous counter ${response?.data?.results[0]?.COUNTER}!`
          );
        }
      } else {
        message.error("Invalid username or password!");
      }
      console.log("res", response);
    }
  };
  return (
    <div className="flex justify-center items-center h-screen login-container">
      <div className="flex flex-col justify-center items-center shadow-[-10px_5px_30px_-10px_rgba(0,0,0,.2)] rounded-xl md:p-8 p-6 lg:4/12 md:w-5/12 md:mx-0 mx-4 w-full form-container">
        <img src={logo} width={200} height={80} alt="" className="mb-2" />
        <Form className="w-full">
          <Form.Item
            name={"userName"}
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <ThemeInput
              label={"User Name"}
              labelPrimary
              placeholder={"username"}
              value={formData?.userName}
              onChange={(e) =>
                setFormData({ ...formData, userName: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            name={"password"}
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <ThemeInput
              password
              label={"Password"}
              labelPrimary
              placeholder={"password"}
              value={formData?.password}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
            />
          </Form.Item>
          <div>
            <p className="text-primary font-semibold mb-1">Counter</p>
            <ThemeSelect
              defaultValue={formData?.pos_counter}
              value={formData?.pos_counter}
              onChange={(e) => setFormData({ ...formData, pos_counter: e })}
              options={counteList || []}
              className={"mb-3 w-full"}
            />
          </div>

          <Form.Item className="mb-2">
            <ThemeButton
              type="primary"
              htmlType="submit"
              onClick={onLogin}
              className={"!h-14 w-40 flex mx-auto mt-4"}
              content={
                <>
                  {isLoading ? (
                    <Spin className="mt-1" />
                  ) : (
                    <div className="flex items-center">
                      <img src={login_icon} alt="" width={21} height={21} />
                      <p className="ml-2 text-lg">Log in</p>
                    </div>
                  )}
                </>
              }
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginScreen;
