import React, { useState, useContext } from "react";
import AppContext from "../../context/appContext";
import { useNavigate } from "react-router-dom";
import { Container, ThemeButton, CashModel } from "../../Components";
import { getUserInfo, updateKey } from "../../helpers/helpers";
import { add_icon } from "../../assets/icons/icons";
import { useOpenCounterMutation } from "../../services/counter";
import moment from "moment/moment";
import { message } from "antd";

const HomeScreen = () => {
  const { appParams } = useContext(AppContext);
  const userInfo = getUserInfo();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [openCounter, { isLoading }] = useOpenCounterMutation();

  const onClose = () => {
    setIsModalOpen(false);
  };

  const onOpenCounter = async () => {
    const response = await openCounter({
      ...appParams,
      pin_posc_cd: userInfo?.POSC_CD,
      pin_dt: moment().format("YYYY-MM-DD"),
      pin_balance: formData?.cash,
      body: {},
    });
    if (!response?.error) {
      setIsModalOpen(false);
      updateKey("userInfo", { ...userInfo, isCounterOpen: true });
      message.success("Counter open successfully");
      navigate("/pos");
    } else {
      message.error("counter already opened");
    }

    console.log("resp", response);
  };
  return (
    <Container>
      <CashModel
        open={isModalOpen}
        onClose={onClose}
        modalType={"open"}
        formData={formData}
        setFormData={(e) => setFormData(e)}
        onSubmit={onOpenCounter}
        isLoading={isLoading}
      />
      <div className="flex flex-col m-6 bg-white shadow-[-10px_5px_30px_-10px_rgba(0,0,0,.2)] px-4 py-4 border-2 border-themeGray-medium rounded-md md:w-6/12 w-full">
        <h1 className="text-xl font-bold text-themeBlack border-b border-b-gray-300 pb-1">
          {getUserInfo()?.COUNTER || ""}
        </h1>
        <div className="flex items-center justify-between my-6">
          <ThemeButton
            onClick={() =>
              userInfo?.isCounterOpen ? navigate("/POS") : setIsModalOpen(true)
            }
            content={
              <div className="flex items-center">
                {!userInfo?.isCounterOpen && (
                  <img src={add_icon} alt="" width={18} height={18} />
                )}
                <p className="ml-2">
                  {userInfo?.isCounterOpen ? "Resume Session" : "New Session"}
                </p>
              </div>
            }
          />
          {/* <div>
            <div className="flex ml-4 mb-2">
              <p className="text-themeBlack font-bold text-base">
                Last Closing Date:
              </p>
              <p className="text-themeBlack text-base ml-3">30/04/2023</p>
            </div>
            <div className="flex ml-4 mb-2">
              <p className="text-themeBlack font-bold text-base">
                Last Closing Cash:
              </p>
              <p className="text-themeBlack text-base ml-3">0.00 Rs.</p>
            </div>
            <div className="flex ml-4 mb-2">
              <p className="text-themeBlack font-bold text-base">Balance:</p>
              <p className="text-themeBlack text-base ml-3">0.00 Rs.</p>
            </div>
          </div> */}
        </div>
      </div>
    </Container>
  );
};

export default HomeScreen;
