import react from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../helpers/helpers";
import { logout_icon } from "../../assets/icons/icons";
import { LeftOutlined, TagsOutlined } from "@ant-design/icons";

const POSNavbar = ({ onClose }) => {
  const navigate = useNavigate();
  return (
    <div className="bg-themeGray-dark px-3 flex items-center justify-between h-12 pos-navbar">
      <div className="flex items-center md:justify-start justify-between md:w-auto w-full">
        {/* <div className="flex items-center"> */}
        {/* <span
          className="mx-2 text-white font-semibold cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <LeftOutlined />
          <LeftOutlined className="-ml-2" />
        </span> */}
        <span className="text-white text-2xl font-bold">POS</span>
        {/* </div> */}
        <div className="flex items-center">
        <span
          className="flex items-center justify-center bg-primary rounded-t-md p-2 h-full text-white font-semibold md:mx-8 cursor-pointer hover:underline md:text-base text-sm"
          onClick={() => navigate("/pos")}
        >
          {getUserInfo()?.COUNTER}
        </span>
        <span
          className="md:mx-8 mx-4 bg-primary rounded-t-md p-2 h-full cursor-pointer hover:underline flex items-center"
          onClick={() => navigate("/orders")}
        >
          <TagsOutlined className="text-white text-lg" />
          <p className="text-white text-base md:flex hidden "> Orders</p>
        </span>
        </div>
      </div>

      <div className="flex item-center h-full">
        <span className={`md:flex hidden items-center mx-2 border-l-2 border-[#808080] px-2 h-full`}>
          <p className="text-white font-semibod">{getUserInfo()?.LOCATION}</p>
        </span>
        <span
          className="flex items-center md:mx-2 border-l-2 border-[#808080] pl-2 md:pr-0 pr-1 h-full cursor-pointer hover:bg-primary"
          onClick={onClose}
        >
          <img src={logout_icon} className="md:w-5 w-6" height={18} />
          <span className="ml-2 text-white font-semibod md:flex hidden">Close</span>
        </span>
      </div>
    </div>
  );
};

export default POSNavbar;
