import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";

export const saleInvoiceApi = createApi({
  reducerPath: "saleInvoiceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async (headers) => {
      headers.set("Content-type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSaleInvoices: builder.query({
      query: ({
        pin_cmp,
        pin_kp,
        pin_keyword1,
        pin_keyword2,
        pin_userid,
        pin_password,
        pin_datatype,
        pin_cust_code,
        pin_inv_no,
        pin_fromdate,
        pin_todate,
        pin_app_for,
      }) => {
        return {
          url: `getsalesinvoices`,
          method: "GET",
          params: {
            pin_cmp,
            pin_kp,
            pin_keyword1,
            pin_keyword2,
            pin_userid,
            pin_password,
            pin_datatype,
            pin_cust_code,
            pin_inv_no,
            pin_fromdate,
            pin_todate,
            pin_app_for,
          },
        };
      },
    }),
    createSaleInvoice: builder.mutation({
      query: ({
        pin_cmp,
        pin_kp,
        pin_keyword1,
        pin_keyword2,
        pin_userid,
        pin_password,
        pin_cust_code,
        file_type,
        file_name,
        pin_term_cd,
        pin_disc_code,
        pin_pfx,
        pin_posc_cd,
        pin_foodpanda_ordno,
        pin_pos_bank_receipt,
        pin_concession,
        pin_rec_notes,
        pin_refund_notes,
        pin_net_received,
        body,
      }) => {
        return {
          url: `/postsalesinvoice`,
          method: "POST",
          body: body,
          params: {
            pin_cmp,
            pin_kp,
            pin_keyword1,
            pin_keyword2,
            pin_userid,
            pin_password,
            pin_cust_code,
            file_type,
            file_name,
            pin_term_cd,
            pin_disc_code,
            pin_pfx,
            pin_posc_cd,
            pin_foodpanda_ordno,
            pin_pos_bank_receipt,
            pin_concession,
            pin_rec_notes,
            pin_refund_notes,
            pin_net_received,
          },
        };
      },
    }),
  }),
});

export const {
  useGetSaleInvoicesQuery,
  useLazyGetSaleInvoicesQuery,
  useCreateSaleInvoiceMutation,
} = saleInvoiceApi;
