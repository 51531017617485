import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import {
  authApi,
  counterApi,
  itemTypesApi,
  productsApi,
  prefixApi,
  payTermApi,
  saleInvoiceApi,
} from "../services/services.js";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [counterApi.reducerPath]: counterApi.reducer,
    [itemTypesApi.reducerPath]: itemTypesApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [prefixApi.reducerPath]: prefixApi.reducer,
    [payTermApi.reducerPath]: payTermApi.reducer,
    [saleInvoiceApi.reducerPath]: saleInvoiceApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      counterApi.middleware,
      itemTypesApi.middleware,
      productsApi.middleware,
      prefixApi.middleware,
      payTermApi.middleware,
      saleInvoiceApi.middleware
    ),
});

setupListeners(store.dispatch);
