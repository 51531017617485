import React from 'react'
import {NavBar} from '../'
const Container = ({children}) => {
  return (
    <div>
      <div><NavBar /></div>
      <div>{children}</div>
    </div>
  )
}

export default Container
