import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/appContext";
import { useNavigate } from "react-router-dom";
import {
  CashModel,
  ThemeSelect,
  SecondContainer,
  ButtonBox,
  ThemeButton,
  CartRow,
  MainCategories,
  SubCategories,
  ProductCard,
} from "../../Components";
import { Skeleton, message, Pagination, Button } from "antd";
import {
  getUserInfo,
  addKeyToStorage,
  updateKey,
  getValueFromStorage,
  deleteKeyFromStorage,
} from "../../helpers/helpers";
import { ArrowRightOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { cart, no_result } from "../../assets/images/images";
import { useLazyGetMinItemTypesQuery } from "../../services/itemTypes";
import { useLazyGetProductsQuery } from "../../services/items";
import { useGetPrefixQuery } from "../../services/prefix";
import { useCloseCounterMutation } from "../../services/counter";
import moment from "moment";

const POSScreen = () => {
  let nf = new Intl.NumberFormat();
  const { appParams } = useContext(AppContext);
  const userInfo = getUserInfo();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(40);
  const [total, setTotal] = useState(0);
  const [activeTab, setActiveTab] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [filterInput, setFilterInput] = useState("");
  const [platform, setPlatform] = useState();
  const [numValue, setNumValue] = useState();
  const [numType, setNumType] = useState("Qty");
  const [activeRow, setActiveRow] = useState();
  const [platformList, setPlatformList] = useState([]);
  const [mainItemTypes, setMainItemType] = useState([]);
  const [products, setProducts] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
  const [selectedMainType, setSelectedMainType] = useState();
  const [selectedSubType, setSelectedSubType] = useState();
  const [checkoutItems, setCheckoutItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [tax, setTax] = useState(0);
  const [getItemTypes, { isLoading }] = useLazyGetMinItemTypesQuery();
  const [getProducts, { isLoading: fetchingProducts }] =
    useLazyGetProductsQuery();
  const { data: prefixes, isLoading: fetchingPrefix } = useGetPrefixQuery({
    ...appParams,
    pin_pfx_type: "SAL",
  });
  const [closeCounter, { isLoading: closingCounter }] =
    useCloseCounterMutation();

  useEffect(() => {
    let list = [];
    prefixes?.results?.length &&
      prefixes?.results?.map((prefix) => {
        list.push({
          value: prefix?.PREFIX,
          label: prefix?.DESCRIPTION,
        });
      });
    setPlatform(list[0]?.value);
    setPlatformList(list);
  }, [prefixes]);

  useEffect(() => {
    getAllItemTypes();
  }, []);

  useEffect(() => {
    getAllProducts();
  }, [selectedMainType, selectedSubType]);

  useEffect(() => {
    let cartInfo = JSON.parse(getValueFromStorage("cartInfo"));
    setCheckoutItems(cartInfo?.items || []);
    setPlatform(cartInfo?.platform?.value);
  }, []);

  useEffect(() => {
    makeTotal();
  }, [checkoutItems]);

  const getAllItemTypes = async () => {
    let mainTypes = [];
    const response = await getItemTypes(appParams);
    response?.data?.results?.length &&
      response?.data?.results?.map((type) => {
        mainTypes.push({
          id: type?.MAINTYPEID,
          name: type?.MAINTYPENAME,
          subTypes: type?.SUBTYPES,
        });
      });
    setMainItemType(mainTypes);
  };

  const getAllProducts = async () => {
    const response = await getProducts({
      ...appParams,
      pin_main_type: selectedMainType?.id,
      pin_sub_type: selectedSubType?.SUBTYPDID,
    });

    setProducts(response?.data?.results || []);
    setTotal(response?.data?.results?.length || 0);
  };

  const addProduct = (data) => {
    let productsList = [...checkoutItems];
    let isProductAdded = productsList.findIndex(
      (product) => product?.PROD_CODE === data?.PROD_CODE
    );
    if (isProductAdded === -1) {
      productsList.push({
        ...data,
        qty: 1,
        lineTotal: Number(data?.PRICE) * 1,
      });
    } else {
      productsList[isProductAdded].qty += 1;
      productsList[isProductAdded].lineTotal =
        productsList[isProductAdded]?.qty * data?.PRICE;
    }
    setActiveRow(data?.PROD_CODE);
    setCheckoutItems(productsList || []);
  };

  const removeItem = (id) => {
    let list = [...checkoutItems];
    list.splice(id, 1);
    setCheckoutItems(list);
  };

  const makeTotal = () => {
    let list = [...checkoutItems];
    let amount = 0;
    list?.length &&
      list?.map((item) => {
        amount += item?.lineTotal;
      });
    setTotalAmount(amount);
  };

  const goToPayment = () => {
    let prefixPlatform = platformList.find((e) => e?.value === platform);
    let cartInfo = {
      platform: {
        value: platform,
        label: prefixPlatform?.label,
      },
      items: checkoutItems,
      tax: tax,
      cartTotal: totalAmount,
    };
    addKeyToStorage("cartInfo", cartInfo);
    navigate("/payment");
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  const onCloseCounter = async () => {
    const response = await closeCounter({
      ...appParams,
      pin_posc_cd: userInfo?.POSC_CD,
      pin_dt: moment().format("YYYY-MM-DD"),
      pin_balance: formData?.cash,
      body: {},
    });
    if (!response?.error) {
      setIsModalOpen(false);
      updateKey("userInfo", { ...userInfo, isCounterOpen: false });
      deleteKeyFromStorage("cartInfo");
      message.success("Session close successfully");
      navigate("/home");
    } else {
      message.error("Something went wrong!");
    }
    console.log("resp", response);
  };

  const setPagination = (page, pageSize) => {
    setMinValue((page - 1) * pageSize);
    setMaxValue(page * pageSize);
    setCurrentPage(page);
  };

  const onCancel = () => {
    setCheckoutItems([]);
    deleteKeyFromStorage("cartInfo");
  };

  const handleBtnClick = (val) => {
    let items = [...checkoutItems];
    let currentRow = items.findIndex((i) => i.PROD_CODE === activeRow);
    if (val === "Qty") {
      setNumType(val);
    } else if (val === "Disc") {
      setNumType(val);
    } else if (val === "x") {
      if (numValue && numValue?.length > 1) {
        let num = numValue;
        var updateNum = num.slice(0, num.length - 1);
        setNumValue(updateNum);
        items[currentRow].qty = Number(updateNum);
        items[currentRow].lineTotal = updateNum * items[currentRow].PRICE;
      } else {
        setNumValue(1);
        items[currentRow].qty = 1;
        items[currentRow].lineTotal = 1 * items[currentRow].PRICE;
      }
    } else if (val === ".") {
      console.log(val);
    } else if (val) {
      if (activeRow) {
        if (val == "00" && val == "0") {
          if (numValue && numValue?.length > 1) {
            let num = numValue ? numValue + val : val;
            setNumValue(num);
            items[currentRow].qty = Number(num);
            items[currentRow].lineTotal = num * items[currentRow].PRICE;
          }
        } else {
          console.log("in");
          let num = numValue ? numValue + val : val;
          setNumValue(num);
          items[currentRow].qty = Number(num);
          items[currentRow].lineTotal = num * items[currentRow].PRICE;
        }
      } else {
        message.error("Please select the row!");
      }
    }
  };

  const btnValues = [
    ["1", "2", "3", "Qty"],
    ["4", "5", "6", "Disc"],
    ["7", "8", "9", ""],
    ["00", "0", ".", "x"],
  ];

  return (
    <>
      <CashModel
        open={isModalOpen}
        onClose={onClose}
        modalType={"close"}
        formData={formData}
        setFormData={(e) => setFormData(e)}
        onSubmit={onCloseCounter}
        isLoading={closingCounter}
      />
      <SecondContainer onClose={() => setIsModalOpen(true)}>
        <div className="flex flex-col justify-between pos-container">
          <div className="flex h-full">
            <div
              className={`md:w-4/12 w-full h-full md:border-r md:!border-themeGray-dark overflow-hidden
              ${activeTab === 2 ? "block" : "md:block hidden"}
              `}
            >
              <div className="w-full h-full">
                <div className="bg-white overflow-auto thin-scroll cart-container">
                  {checkoutItems?.length ? (
                    <>
                      <div>
                        {checkoutItems?.map((product, index) => {
                          return (
                            <CartRow
                              key={index}
                              index={index}
                              product={product}
                              activeRow={activeRow}
                              setActiveRow={(e) => setActiveRow(e)}
                              removeItem={(e) => removeItem(e)}
                            />
                          );
                        })}
                      </div>
                      <div className="flex justify-end items-center px-4 py-2">
                        <span className="flex flex-col items-center">
                          <p className="font-bold text-lg">
                            Total:{" "}
                            <span>{`${nf.format(totalAmount)} PKR`}</span>
                          </p>
                          <p className="font-semibold">Taxes: {`${tax} PKR`}</p>
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className="h-full w-full flex items-center justify-center">
                      <img src={cart} className="w-20 h-20 opacity-40" />
                    </div>
                  )}
                </div>
                <div className="border-t-2 border-primary px-4 flex flex-col  cart-action-container">
                  <p className=" font-semibold text-primary mb-1 md:text-base text-sm">
                    Select a Platform
                  </p>
                  <ThemeSelect
                    defaultValue={platform}
                    value={platform}
                    onChange={(e) => setPlatform(e)}
                    options={platformList}
                    className={"w-full"}
                    placeholder={"select a platform"}
                  />
                  <div className="mt-6">
                    <ButtonBox>
                      {btnValues.flat().map((btn, i) => {
                        return (
                          <button
                            className={`md:py-2 py-1 text-center text-lg font-semibold border border-[#b1b1b1] hover:bg-primary hover:text-white cursor-pointer ${
                              numType === btn && "bg-primary text-white"
                            }`}
                            onClick={() => handleBtnClick(btn)}
                          >
                            {btn}
                          </button>
                        );
                      })}
                    </ButtonBox>
                  </div>
                  <div className="flex justify-between cart-btn-container">
                    <span className="pr-2 w-6/12">
                      <Button
                        className="w-full font-semibold bg-primary text-white hover:!border-primary hover:!text-white flex items-center justify-center cart-btns"
                        icon={<CloseCircleOutlined />}
                        onClick={() => onCancel()}
                      >
                        Cancel
                      </Button>
                    </span>
                    <span className="pl-2 w-6/12">
                      <Button
                        className="w-full font-semibold  bg-themeGreen text-white hover:!border-themeGreen hover:!text-white flex items-center justify-center cart-btns"
                        icon={<ArrowRightOutlined />}
                        onClick={() => goToPayment()}
                      >
                        Payment
                      </Button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`flex-col md:w-8/12 w-full h-full${
                activeTab === 1 ? "flex" : "md:flex hidden"
              }`}
            >
              <div
                className={`md:border-l md:border-themeGray-dark w-full h-full overflow-hidden`}
              >
                <div className="h-26">
                  <MainCategories
                    setSelectedMainType={(e) => setSelectedMainType(e)}
                    setSelectedSubType={(e) => setSelectedSubType(e)}
                    mainItemTypes={mainItemTypes}
                    selectedMainType={selectedMainType}
                  />
                  <SubCategories
                    selectedMainType={selectedMainType}
                    selectedSubType={selectedSubType}
                    setSelectedSubType={(e) => setSelectedMainType}
                  />
                </div>
                <div className="flex flex-col justify-between overflow-y-auto overflow-x-hidden product-container">
                  {fetchingProducts ? (
                    <Skeleton className="p-4" />
                  ) : (
                    <>
                      {products.length > 0 ? (
                        <div className="flex flex-wrap p-4 ">
                          {products
                            .slice(minValue, maxValue)
                            .map((product, index) => {
                              return (
                                <ProductCard
                                  key={index}
                                  product={product}
                                  addProduct={(e) => addProduct(e)}
                                />
                              );
                            })}
                        </div>
                      ) : (
                        <div className="flex items-center justify-center p-4 h-[75vh]">
                          <img src={no_result} className="w-80 h-80" />
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="flex justify-center mt-6 h-10 w-full">
                  <Pagination
                    defaultCurrent={1}
                    total={total}
                    current={currentPage}
                    defaultPageSize={40}
                    pageSizeOptions={[40, 80, 500, 1000, 10000]}
                    onChange={(page, pageSize) => setPagination(page, pageSize)}
                    responsive={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div span={24} className="md:hidden flex items-center h-14 fixed bottom-0  bg-themeGray-medium w-full px-2">
            <span className="w-6/12 px-1">
              <ThemeButton
                className={"w-full !h-12"}
                content={<p>Products</p>}
                onClick={() => setActiveTab(1)}
              />
            </span>
            <span className="w-6/12 px-1">
              <ThemeButton
                className={"w-full !h-12 !bg-themeGreen"}
                content={<p>Checkout</p>}
                onClick={() => setActiveTab(2)}
              />
            </span>
          </div>
        </div>
      </SecondContainer>
    </>
  );
};

export default POSScreen;
