import React, { useState } from "react";
import { Modal, Row, Col } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import { ThemeTable } from "../";

const OrderDetailModal = ({ isOpen, onClose, data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "6%",
      render: (item, record, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "Product",
      dataIndex: "PRODUCT",
      key: "1",
      render: (text, data, id) => (
        <p className="text-center font-medium">{text}</p>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "QTY",
      key: "QTY",
      render: (text) => <p className="text-center font-medium">{text}</p>,
    },
    {
      title: "Rate",
      dataIndex: "RATE",
      key: "RATE",
      render: (text) => <p className="text-center font-medium">{text}</p>,
    },
    {
      title: "Amount",
      dataIndex: "AMOUNT",
      key: "AMOUNT",
      render: (text) => <p className="text-center font-medium">{text}</p>,
    },
  ];
  return (
    <Modal open={isOpen} closable={false} footer={false} width={800} centered>
      <div>
        <div className="flex items justify-between">
          <h1 className="text-xl font-bold font-Monsterrate">Order Detail</h1>
          <span className="cursor-pointer" onClick={onClose}>
            <CloseCircleFilled className="text-xl" />
          </span>
        </div>
        <Row className="mt-8">
          <Col lg={12} md={12} sm={12} className="mb-4">
            <p className="text-primary font-bold text-lg">Customer:</p>
            <p className="text-base text-black">{data?.CUSTOMER}</p>
          </Col>
          <Col lg={12} md={12} sm={12} className="mb-4">
            <p className="text-primary font-bold text-lg">Order Date:</p>
            <p className="text-base text-black">{data?.INVDATE}</p>
          </Col>
          <Col lg={12} md={12} sm={12} className="mb-4">
            <p className="text-primary font-bold text-lg">Amount:</p>
            <p className="text-base text-black">{data?.SUB_TOTAL} Rs</p>
          </Col>
          <Col lg={12} md={12} sm={12} className="mb-4">
            <p className="text-primary font-bold text-lg">Paid:</p>
            <p className="text-base text-black">{data?.PAID}</p>
          </Col>
        </Row>
        <div className="mt-6">
          <ThemeTable
            columns={columns}
            data={data?.INVOICES}
            pagination={{ total, setCurrentPage, setResultPerPage }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default OrderDetailModal;
