import React, { useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AppContext from "../../context/appContext";
import { getUserInfo } from "../../helpers/helpers";
import { SecondContainer, ThemeButton } from "../../Components";
import { logo, vs_logo } from "../../assets/images/images";
import { useGetSaleInvoicesQuery } from "../../services/saleInvoice";
import dayjs from "dayjs";
import ReactToPrint from 'react-to-print';

const InvoicePrint = () => {
  const ref = useRef()
  const { id } = useParams();
  const { appParams } = useContext(AppContext);
  const userInfo = getUserInfo();
  const navigate = useNavigate();
  const { data, isLoading } = useGetSaleInvoicesQuery({
    ...appParams,
    pin_datatype: "INVITEMS",
    pin_inv_no: id,
  });

  console.log("data", data);

  return (
    <SecondContainer>
      
      <div className="flex flex-col items-center w-full pt-4 px-4">
        <div className="md:w-5/12 w-full flex items-center justify-between">
          {/* <ThemeButton className={"mr-2"} content={<p>Print</p>} /> */}
          <ReactToPrint
          trigger={() => {
            return <ThemeButton content={<p>Print</p>} />;
          }}
          content={() => ref.current}
          documentTitle={`invoice-print-${id}`}
          pageStyle={"print"}
        />
          <h1 className="sm:text-2xl text-xl font-bold">Invoice Print</h1>
          <ThemeButton
            className={"ml-2"}
            content={<p>New Order</p>}
            onClick={() => navigate("/POS")}
          />
        </div>
        <div ref={ref} className="bg-white md:w-5/12 w-full flex flex-col items-center my-4 p-1 py-6">
          <img src={logo} alt="" className="w-20 h-20 mb-3" />
          <p className="text-sm text-center text-black mb-1">
            International Franchises - {userInfo?.LOCATION}
          </p>
          <p className="text-sm text-center text-black mb-1">
            Owned and Oprated by International Franchises Pvt Ltd
          </p>
          <p className="text-sm text-center text-black mb-1">
            {userInfo?.LOCATION} Outlet Karachi
          </p>
          <p className="text-sm text-center text-black mb-1 mt-3">
            http://www.international-franchises.pk
          </p>
          <p className="text-sm text-center text-black mb-1">
            SNTN # 0816271-1
          </p>
          <div className="flex flex-col w-full px-2 my-2">
            <div className="flex items-center justify-between mb-1">
              <div className="flex items-center">
                <p className="mr-2 text-sm">Date:</p>
                <p className="text-sm">{data?.results[0]?.INVDATE}</p>
              </div>
              <div className="flex items-center">
                <p className="mr-2 text-sm">Order#</p>
                <p className="text-sm">{data?.results[0]?.INVNO}</p>
              </div>
            </div>
            <div className="flex items-center justify-between mb-1">
              <div className="flex items-center">
                <p className="mr-2 text-sm">Time:</p>
                <p className="text-sm">
                  {dayjs(data?.results[0]?.ENTRY_DATETIME).format("hh:mm:ss A")}
                </p>
              </div>
              <div className="flex items-center">
                <p className="mr-2 text-sm">Cashier:</p>
                <p className="text-sm">{userInfo?.COUNTER}</p>
              </div>
            </div>
          </div>
          <h1 className="text-center text-base mb-2">
            {data?.results[0]?.DOC_DESCR}
          </h1>

          <table className="text-sm w-full">
            <thead className="border-b border-dashed border-black">
              <tr>
                <td className="font-bold text-center p-2">Qty</td>
                <td className="font-bold  p-2">Item(s)</td>
                <td className="font-bold text-right p-2">Total</td>
              </tr>
            </thead>
            <tbody className="border-b border-dashed border-black">
              {data?.results[0]?.INVOICES?.map((row, index) => {
                return (
                  <tr key={index}>
                    <td
                      className={`text-center text-xs ${
                        index == 0
                          ? "pt-4 p-1"
                          : index == data?.results[0]?.INVOICES?.length - 1
                          ? "pb-5 p-1"
                          : "p-1"
                      }`}
                    >
                      {row?.QTY}
                    </td>
                    <td
                      className={`text-xs ${
                        index == 0
                          ? "pt-4 p-1"
                          : index == data?.results[0]?.INVOICES?.length - 1
                          ? "pb-5 p-1"
                          : "p-1"
                      }`}
                    >
                      {row?.PRODUCT}
                    </td>
                    <td
                      className={`text-right text-xs ${
                        index == 0
                          ? "pt-4 p-1"
                          : index == data?.results[0]?.INVOICES?.length - 1
                          ? "pb-5 p-1"
                          : "p-1"
                      }`}
                    >
                      {row?.AMOUNT}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot className="">
              <tr>
                <td colspan={2} className="pt-4 p-1">
                  Subtotal
                </td>
                <td className="text-right pt-4 p-1">
                  {data?.results[0]?.SUB_TOTAL}
                </td>
              </tr>
              <tr className="border-b border-dashed border-black">
                <td colspan={2} className="pb-4 p-1">
                  Sales Tax 13%
                </td>
                <td className="text-right pb-4 p-1">{127}</td>
              </tr>
              <tr className="border-b border-dashed border-black">
                <td colspan={2} className="py-4 px-1">
                  POS Service Fee
                </td>
                <td className="text-right py-4 px-1">{1}</td>
              </tr>
              <tr className="border-b border-dashed border-black">
                <td colspan={2} className="py-4 px-1">
                  Total
                </td>
                <td className="text-right py-4 px-1">
                  {data?.results[0]?.SUB_TOTAL}
                </td>
              </tr>
              <tr>
                <td colspan={2} className="pt-4 p-1">
                  {data?.results[0]?.TERM_DESCR}
                </td>
                <td className="text-right pt-4 p-1">
                  {data?.results[0]?.TERM_CD == "001"
                    ? data?.results[0]?.NET_RECEIVED
                    : data?.results[0]?.REC_NOTES}
                </td>
              </tr>
              {data?.results[0]?.TERM_CD == "001" && (
                <tr className="border-b border-dashed border-black">
                  <td colspan={2} className="pb-4 p-1">
                    Change
                  </td>
                  <td className="text-right pb-4 p-1">
                    {data?.results[0]?.REFUND_NOTES}
                  </td>
                </tr>
              )}
            </tfoot>
          </table>

          <p className="text-sm text-center text-black mt-4">
            For home delivery and comments / suggestions please call us at 021
            111 366 996 or email at feedback@intfranchise.pk. Thank You.
          </p>
          <div className="flex flex-col items-center justify-center mt-4">
            <img src={vs_logo} alt="" className="w-20 h-20" />
            <p className="text-xs -mt-6">www.visionsoft.com</p>
          </div>
        </div>
      </div>
    </SecondContainer>
  );
};

export default InvoicePrint;
