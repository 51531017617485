import React from "react";

const SubCategories = ({ selectedMainType, selectedSubType, setSelectedSubType }) => {
  return (
    <div className="bg-[#e2e2e2] border-b-2 border-primary">
      <ul className="flex items-center overflow-auto w-full thin-scroll">
        {selectedMainType &&
          selectedMainType?.subTypes?.length > 0 &&
          selectedMainType?.subTypes?.map((subType, index) => {
            return (
              <li
                key={index}
                className={`py-3 px-4 text-sm font-semibold border-r border-[#b1b1b1] whitespace-nowrap cursor-pointer hover:bg-[#fff2e8] hover:text-primary ${
                  selectedSubType?.SUBTYPDID === subType?.SUBTYPDID &&
                  "bg-[#fff2e8] text-primary"
                }`}
                onClick={() => setSelectedSubType(subType)}
              >
                {subType?.SUBTYPENAME}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default SubCategories;
