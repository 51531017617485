import React from "react";
import { POSNavbar } from "..";

const SecondContainer = ({ children, onClose }) => {
  return (
    <div className="h-screen">
      <div className="fixed top-0 w-full z-[1000]">
        <POSNavbar onClose={onClose}/>
      </div>
      <div className=" pt-12 h-screen">{children}</div>
    </div>
  );
};

export default SecondContainer;
